import {
  Box,
  Center,
  Spinner,
  VStack,
} from "@chakra-ui/react";

import SCHEMAS from "src/res/demo-node-types.json";
import { useState } from "react";
import { ReactFlow, useNodesState } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { createCustomNodes } from "src/appUtils";
import annotationNode from "./annotation-node";
import AnimatedSVGEdge from "./animated-edge";

const initialNodes = {
  automatic_sync_after_call: [
    {
      id: 'annotation-1',
      type: 'annotation',
      draggable: false,
      selectable: false,
      data: {
        level: 1,
        label: '_demo:automatic_sync_after_call:annotation-1',
        arrowStyle: {
          right: 0,
          bottom: 0,
          transform: 'translate(-20px,10px) rotate(-80deg)',
        },
      },
      position: { x: -200, y: -30 },
    },
    {
      id: 'annotation-2',
      type: 'annotation',
      draggable: false,
      selectable: false,
      data: {
        level: 2,
        label: '_demo:automatic_sync_after_call:annotation-2',
        arrowStyle: {
          left: 0,
          bottom: 0,
          transform: 'scaleX(-1) rotate(-80deg) translate(10px, 0px)',
        },
      },
      position: { x: 185, y: 250 },
    },
    {
      id: "leexi",
      type: "Zoom",
      data: {
        src_handles: ["summary", "speakers"],
        name: "New meeting summary",
        overrides: {
          kls: "Meeting",
          icon: "teams.png",
        },
      },
      position: { x: 0, y: 0 },
    },
    {
      id: "gpt",
      type: "Plurally",
      data: {
        tgt_handles: ["summary", "speakers"],
        src_handles: ["hubspot_commands"],
        name: "Generate HubSpot objects",
        overrides: {
          kls: "AI",
          isExpanded: false,
          icon: "openai.svg",
          borderRadius: "full",
          className: "animated-border"
        },
      },
      position: { x: 8, y: 170 },
    },
    {
      id: "hubspot",
      type: "HubspotAuto",
      data: {
        tgt_handles: ["hubspot_commands"],
        overrides: {
          kls: "HubSpot",
        },
        name: "Sync HubSpot",
      },
      input_schema: {
        properties: {
          hubspot_commands: {
            type: "array",
            title: "HubSpot commands",
            items: {
              type: "string",
              title: "Command",
            },
          },
        },
      },
      position: { x: 15, y: 306 },
    },
  ], automatic_lead_qualification: [
    {
      id: 'annotation-1',
      type: 'annotation',
      draggable: false,
      selectable: false,
      data: {
        level: 1,
        label: '_demo:automatic_lead_qualification:annotation-1',
        arrowStyle: {
          left: 0,
          bottom: 0,
          transform: 'scaleX(-1) rotate(-80deg) translate(10px, 0px)',
        },
      },
      position: { x: 200, y: -13 },
    },
    {
      id: 'annotation-2',
      type: 'annotation',
      draggable: false,
      selectable: false,
      data: {
        level: 2,
        label: '_demo:automatic_lead_qualification:annotation-2',
        arrowStyle: {
          right: 0,
          bottom: 0,
          transform: 'translate(-20px,10px) rotate(-80deg)',
        },
      },
      position: { x: -200, y: 250 },
    },
    {
      id: "leexi",
      type: "Zoom",
      data: {
        src_handles: ["summary", "speakers"],
        name: "_demo:automatic_lead_qualification:node-1:name",
        overrides: {
          kls: "_demo:automatic_lead_qualification:node-1:title",
          icon: "outlook.png",
        },
      },
      position: { x: 0, y: 0 },
    },
    {
      id: "gpt",
      type: "Plurally",
      data: {
        tgt_handles: ["summary", "speakers"],
        src_handles: ["hubspot_commands"],
        name: "Generate HubSpot objects",
        overrides: {
          kls: "AI",
          isExpanded: false,
          icon: "openai.svg",
          borderRadius: "full",
          className: "animated-border"
        },
      },
      position: { x: 144, y: 156 },
    },
    {
      id: "hubspot",
      type: "HubspotAuto",
      data: {
        tgt_handles: ["hubspot_commands"],
        overrides: {
          kls: "_demo:automatic_lead_qualification:node-2:title",
          icon: "salesforce.svg"
        },
        name: "_demo:automatic_lead_qualification:node-2:name",
      },
      input_schema: {
        properties: {
          hubspot_commands: {
            type: "array",
            title: "HubSpot commands",
            items: {
              type: "string",
              title: "Command",
            },
          },
        },
      },
      position: { x: 15, y: 306 },
    },
  ]
};

const initialEdges = {
  automatic_sync_after_call: [
    {
      id: "leexi-gpt-summary",
      source: "leexi",
      target: "gpt",
      sourceHandle: "summary",
      targetHandle: "summary",
      animated: false,
      label: '#e0f6de',
      type: 'animatedSvg',
    },
    {
      id: "leexi-gpt-speakers",
      source: "leexi",
      target: "gpt",
      sourceHandle: "speakers",
      targetHandle: "speakers",
      animated: false,
      label: '#e0f6de',
      type: 'animatedSvg',
    },
    {
      id: "gpt-hubspot-commands",
      source: "gpt",
      target: "hubspot",
      sourceHandle: "hubspot_commands",
      targetHandle: "hubspot_commands",
      animated: false,
      label: '#CFF5E7',
      type: 'animatedSvg',
    },
  ], automatic_lead_qualification: [
    {
      id: "leexi-gpt-summary",
      source: "leexi",
      target: "gpt",
      sourceHandle: "summary",
      targetHandle: "summary",
      animated: false,
      label: '#e0f6de',
      type: 'animatedSvg',
    },
    {
      id: "leexi-gpt-speakers",
      source: "leexi",
      target: "gpt",
      sourceHandle: "speakers",
      targetHandle: "speakers",
      animated: false,
      label: '#e0f6de',
      type: 'animatedSvg',
    },
    {
      id: "gpt-hubspot-commands",
      source: "gpt",
      target: "hubspot",
      sourceHandle: "hubspot_commands",
      targetHandle: "hubspot_commands",
      animated: false,
      label: '#CFF5E7',
      type: 'animatedSvg',
    },
  ]
};


const Demo = ({ useCase }) => {
  const [customNodes] = useState(createCustomNodes({
    nodeTypes: SCHEMAS,
    isDemo: true,
  }));
  const [nodes, _, onNodesChange] = useNodesState(initialNodes[useCase])
  const [edges] = useState(initialEdges[useCase]);
  return (
    <VStack h="100%" align="stretch">
      <Box h="100%">
        {customNodes ? (
          <ReactFlow
            // nodeExtent={nodeExtent}
            deleteKeyCode={null}
            onNodesChange={(changes) => {
              changes.forEach((change) => {
                if (change.position) {
                  console.debug(change.position.x, change.position.y);
                }
              });
              onNodesChange(changes)
            }}
            onEdgesChange={() => null}
            onConnect={() => null}
            edgesFocusable={false}
            // nodesDraggable={false}
            nodesConnectable={false}
            nodesFocusable={true}
            elementsSelectable={false}
            // zoomOnScroll={false}
            panOnScroll={false}
            preventScrolling={false}
            nodeTypes={{ ...(customNodes || {}), annotation: annotationNode }}
            nodes={nodes}
            edges={edges}
            edgeTypes={{
              animatedSvg: AnimatedSVGEdge,
            }}
            // panOnDrag={false}
            fitView
          // maxZoom={1}
          // minZoom={1}
          >
            {/* <Background /> */}
            {/* <Controls /> */}
          </ReactFlow>
        ) : (
          <Center>
            <Spinner />
          </Center>
        )}
      </Box>
    </VStack>
  );
};

export default Demo;
