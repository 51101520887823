import { BaseEdge, EdgeProps, getBezierPath, getSmoothStepPath } from "@xyflow/react";
const TIMEOUT = 1000
const AnimatedSVGEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    label,
}: EdgeProps) => {
    const [edgePath] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <BaseEdge id={id} path={edgePath} />
            {/* <TextWithBackground text={label}> */}
            <circle r="8" fill={label}>
                <animateMotion dur={`${TIMEOUT / 1000 * 2}s`}
                    repeatCount="indefinite"
                    path={edgePath}
                    keyTimes="0; 0.8; 1"
                    keySplines="0.42 0 0.58 1; 0 0 0 0" />
            </circle>
            {/* </TextWithBackground> */}
        </>
    );
}

export default AnimatedSVGEdge