import {
  Box,
  Heading,
  Text,
  useColorModeValue,
  Image as ChakraImage,
  Flex,
  VStack,
  AvatarGroup,
  Avatar,
  HStack,
  SimpleGrid,
  GridItem,
  Icon,
  Button,
  Link,
  Tag,
  FormLabel,
  Input,
  StackDivider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  TabIndicator,
} from "@chakra-ui/react";

import Pricing from "src/home/pricing";
import Cta from "../../home/Cta";
import LandingContainer from "../../home/LandingContainer";
import RequestIntegration from "../../home/request-integration";
import { ReactFlowProvider } from "@xyflow/react";
import React from "react";

import { MdStar } from "@react-icons/all-files/md/MdStar";
import { FaCaretRight } from "@react-icons/all-files/fa/FaCaretRight";
import { FaLink } from "@react-icons/all-files/fa/FaLink";
import { FaHammer } from "@react-icons/all-files/fa/FaHammer";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";

import { useData } from "vike-react/useData";
import { ExploreCard, TEMPLATES_BGS } from "src/components/explore";
import { createCustomNodes } from "src/appUtils";
import Faq from "src/components/faq";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { FaRocket } from "@react-icons/all-files/fa/FaRocket";
import T from "src/renderer/local-text";
import AnimatedFlow from "src/components/demo-animated";
import { FaWatchmanMonitoring } from "react-icons/fa";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Demo from "src/components/demo";
import GraphBackground from "src/components/animated-bg";

const HeroTitle = ({ children }) => (
  <Heading
    // as='h2'
    // lineHeight={1.1}
    // fontWeight="bold"
    // letterSpacing={"-3px"}
    // fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}
    w='full' textAlign={{ base: 'center', md: 'start' }}
  >
    {children}
  </Heading>
);

const LazyVideo = () => {
  return <LiteYouTubeEmbed
    noCookie
    allowFullScreen
    className='video'
    style={{
      width: '100%',
      height: '100%',
      border: 'none',
    }}
    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
    id="Ilka2CUDGQ0"
    playlabel="Plurally Demo Video" />
}

const Hero = () => {
  const [hue, setHue] = React.useState(-101)
  const [saturation, setSaturation] = React.useState(0.5)
  const [brightness, setBrightness] = React.useState(1)
  return (
    <LandingContainer id="demo" py='20'>
      <SimpleGrid
        spacing={{ base: 8, md: 10 }}
        columns={{ base: 1, md: 12 }}
      >
        <GridItem
          as={VStack}
          colSpan={{ base: 12, md: 6, lg: 5 }}
          spacing={{ base: 4, md: 8 }}
          px={{ base: 4, md: 0 }}
          py={{ base: 8, md: 0 }}
          justify="center"
          position="relative"
          overflow="hidden" // Ensures the background doesn't overflow
          rounded='10'
          backdropFilter={{ base: 'blur(10px)', md: 'inherit' }}
          shadow={{ base: 'lg', md: 'inherit' }}
          bg={{ base: 'rgba(255, 255, 255, 0.5)', md: 'inherit' }}
          zIndex='1'
        >
          <VStack
            align={{ base: "center", md: "start" }}>
            <HStack
              fontSize="sm"
              mb="2"
              p='2'
              borderColor="brand.50"
              // borderWidth="2px"
              shadow="md"
              backdropFilter='blur(10px)'
              rounded="full"
              cursor="pointer"
              bg='white'
            >
              <Tag fontWeight='bold' size="sm" bg="brandTernary.200" rounded="full">
                <T>New</T>
              </Tag>
              <Text><T>_lp:new_badge:content</T></Text>
            </HStack>
            <HeroTitle>
              <T>_lp:hero</T>{" "}
            </HeroTitle>
          </VStack>
          <VStack spacing={0} py='4' position='relative'>
            <Text as="h1" w="full" textAlign={{ base: "center", md: "start" }}>
              <T>_lp:h1</T>
            </Text>
            <Text w="full" textAlign={{ base: "center", md: "start" }}>
              <T>_lp:h1:sub</T>
            </Text>
            <ChakraImage
              position="absolute"
              left="-9900px"
              src={`${import.meta.env.VITE_PUBLIC_HOST}/plurally-cover.jpeg`}
              alt="Plurally Hero Image"
            />
          </VStack>
          <VStack
            spacing={{ base: 4, sm: 6 }}
            align={{ base: "center", md: "stretch" }}
            w="full"
          >
            <Cta size="lg" withDemo />
            <HStack display="none">
              <AvatarGroup size="sm" max={5}>
                {["bart", "alexa", "const", "nico"].map((name) => (
                  <Avatar key={name} name={name} src={`testimonials/${name}.webp`} />
                ))}
              </AvatarGroup>
              <VStack align="start" spacing="0">
                <HStack spacing="-2" color="#FFBF00">
                  <MdStar />
                  <MdStar />
                  <MdStar />
                  <MdStar />
                  <MdStar />
                </HStack>
                <Text>
                  <Text fontWeight="bold" as="span">
                    1000+
                  </Text>{" "}
                  flows created!
                </Text>
              </VStack>
            </HStack>
          </VStack>
        </GridItem>
        <GridItem colSpan={{ md: 6, lg: 7 }} h='full' w='full' display={{ base: 'none', md: 'inherit' }} >
          <Box p='4'
            position='relative'
            className="lp-card"
          // shadow='2xl'
          >
            <Box
              className="lp-bg-img"
              backgroundImage={`url('/landing/bg13.jpeg')`}
            />
            <Box bg='white' p='4' borderRadius='10' shadow='xl'>
              <ReactFlowProvider>
                <AnimatedFlow />
              </ReactFlowProvider>
            </Box>
          </Box>
          {/* <Slider aria-label='slider-ex-2' colorScheme='pink' value={hue} onChange={setHue} min={-180} max={180}>
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Slider aria-label='slider-ex-2' colorScheme='pink' value={saturation} onChange={setSaturation} min={0} max={1} step={0.01}>
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Slider aria-label='slider-ex-2' colorScheme='pink' value={brightness} onChange={setBrightness} min={0} max={1} step={0.01}>
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          {i}-
          {hue}-
          {saturation} */}
        </GridItem>
      </SimpleGrid>

      {/* <Text>
              Flows are built by connecting blocks together to form a pipeline.
              This intuitive way of building AI flows is accessible to anyone,
              regardless of their technical background and gives very fine
              control over the logic.
            </Text> */}
    </LandingContainer >
  );
};

const IntegrationLogos = () => {
  return <VStack align="center" justify='center' h='full' >
    <Text fontWeight="bold" textColor="gray.400">
      <T>
        Integrates with
      </T>
    </Text>
    <Flex justify="center" align="center" wrap="wrap" gap={6}>
      <Box>
        <ChakraImage src="/logos/gmail.svg" alt="GMail" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/iphone-voice-recorder.png" alt="Slack" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/hubspot.svg" alt="Hubspot" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/leexi_logo.jpeg" alt="Leexi" boxSize="50px" />
      </Box>
      {/* <Box>
        <ChakraImage src="/logos/openai.svg" alt="OpenAI" boxSize="50px" />
      </Box> */}
      <Box>
        <ChakraImage src="/logos/salesforce.svg" alt="Salesforce" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/hunter.svg" alt="Hunter" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/slack.svg" alt="Slack" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/instagram.svg" alt="Instagram" boxSize="50px" />
      </Box>
      <Box>
        <ChakraImage src="/logos/gsheets.svg" alt="GSheets" boxSize="50px" />
      </Box>
    </Flex>
    <Text fontWeight="bold" textColor="gray.400" mb='4'>
      <T>
        And many more
      </T>
    </Text>
  </VStack>
}

const Step = ({
  icon,
  title,
  text,
  selected,
  onClick
}) => {
  const color = selected ? 'brand.500' : 'inherit'
  return <Flex
    borderStartColor={color}
    borderStartWidth={selected ? '2px' : '0px'}
    onClick={onClick}
    justify='space-between'
    align={'center'}
    gap='4'
    _hover={{ bg: 'brand.50' }}
    p={4}
    cursor='pointer'>
    <Flex w={12} h={12} align={'center'} justify={'center'} rounded={'full'} >
      <Icon as={icon} w='6' h='6' color={color} />
    </Flex>
    <Box flex='1'>
      <Heading size='md' as='h3' color={color}>
        {title}
      </Heading>
      <Text color={selected ? 'inherit' : 'gray.600'}>{text}</Text>
    </Box>
    <Icon as={FaCaretRight} color={color} />
  </Flex>
}

const Step1 = ({ onClick }) => {
  const INTEGRATIONS = [
    ['hubspot', 'HubSpot'],
    ['gmail', 'Gmail'],
    ['salesforce', 'Salesforce'],
    ['gsheets', 'Google Sheets'],
    ['slack', 'Slack'],
    ['instagram', 'Instagram'],
    // ['shopify', 'Shopify'],
  ]
  const [integration, setIntegration] = React.useState(0)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIntegration(prevIntegration => (prevIntegration + 1) % INTEGRATIONS.length);
    }, 2000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);


  return (<Flex className="lp-card" borderColor={useColorModeValue("gray.300", "gray.500")} direction='column' align='center' gap={4} position='relative' p='4' >
    <Tag colorScheme="brand" size='lg' borderRadius='10' gap={2} fontWeight='bold' p={{ base: 2, lg: 2 }} position='absolute' top='0' left='0%' transform='translate(20%, -50%)'>
      <Icon as={FaLink} />
      <Text>
        <T>
          Integrate your services
        </T>
      </Text>
    </Tag>
    <VStack p={{ base: 4, md: 8 }} align='stretch'>
      <HStack align='center'>
        <Avatar borderWidth='2px' borderColor='gray.200' size={{ base: 'lg', md: 'xl' }} src={`/logos/${INTEGRATIONS[integration][0]}.svg`} aria-label={`${INTEGRATIONS[integration][0]} logo`} name={`${INTEGRATIONS[integration][0]} logo`} />
        <Icon as={FaArrowRight} color='gray.400' fontSize='2xl' />
        <Avatar borderWidth='2px' borderColor='gray.200' size={{ base: 'lg', md: 'xl' }} src={`/logo-square-white.jpg`} aria-label='Plurally logo' name='Plurally logo' />
      </HStack>
      <Heading>Plurally</Heading>
      <Text><T>_lp:step1:p1:1</T>{' '}<Text fontSize='2xl' fontWeight='bold' as='span'>{INTEGRATIONS[integration][1]}</Text><T>_lp:step1:p1:2</T></Text>
      <Text>
        <T>
          _lp:step1:p2
        </T>
      </Text>
      <HStack w='full' justify='end' mt='2'>
        <Button colorScheme="brand" onClick={onClick}>
          <T>
            Continue
          </T>
        </Button>
      </HStack>
    </VStack>
  </Flex >)
}

const TableRow = ({ type, head, details }) => {
  return (
    <AccordionItem>
      <AccordionButton
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={4}
        p={4}>
        <Text align='start' fontWeight='bold' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
          <T>{head}</T>
        </Text>
        <HStack>
          <Tag bg={{
            Created: 'green.100',
            Updated: 'blue.100',
            Associated: 'purple.100'
          }[type]} >{type}</Tag>
          <ChevronDownIcon visibility={!details && 'hidden'} fontSize="24px" />
        </HStack>
      </AccordionButton>
      {details && <AccordionPanel pb={4}>
        <TableContainer whiteSpace='wrap' overflow='auto'>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>
                  <T>
                    Field
                  </T>
                </Th>
                {
                  type === 'Updated' && <Th>
                    <T>
                      Old Value
                    </T>
                  </Th>
                }
                <Th>
                  <T>
                    New Value
                  </T>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                Object.entries(details).map(([key, value]) => <Tr key={key}>
                  <Td>
                    <T>
                      {key}
                    </T>
                  </Td>
                  {type === 'Updated' ? <>
                    <Td>
                      <T>
                        {value[0]}
                      </T>
                    </Td>
                    <Td>
                      <T>
                        {value[1]}
                      </T>
                    </Td>
                  </> :
                    <Td>
                      <T>
                        {value}
                      </T>
                    </Td>}
                </Tr>
                )
              }
            </Tbody>
          </Table>
        </TableContainer>
      </AccordionPanel>}
    </AccordionItem>
  )
}

const UseCase = ({ useCase, ...props }) => {
  return (<Box className="lp-card" height={"500px"} width={"full"} position='relative' p='4'>
    <Box
      className='lp-bg-img'
      {...props}
    />
    <Box bg='white' h='full' w='full' rounded='10' shadow='md'>
      <ReactFlowProvider>
        <Demo useCase={useCase} />
      </ReactFlowProvider>
    </Box>
  </Box>)
}

const UseCases = () => {
  return (
    <LandingContainer subtitle={<T>Use cases</T>} title={<T>_lp:use_cases:title</T>} desc={<T>
      _lp:use_cases:p
    </T>}>
      <Tabs align='center' size='lg' variant='unstyled' gap='8'>
        <TabList gap='4'>
          {[['_demo:automatic_sync_after_call:title', '#DCDEF680'], ['_demo:automatic_inbound_qualification:title', '#e0f6de80']].map(([tab, bg]) => (
            <Tab key={tab} shadow='md' rounded='10' backdropFilter='blur(10px)' _selected={{ fontWeight: 'bold', bg }}>
              <T>{tab}</T>
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {
            [
              {
                useCase: 'automatic_sync_after_call',
                backgroundImage: `url('/landing/bg14.jpeg')`,
                filter: 'hue-rotate(30deg)'
              },
              {
                useCase: 'automatic_lead_qualification',
                backgroundImage: `url('/landing/bg13.jpeg')`,
                filter: 'hue-rotate(-40deg)'
              }
            ].map((props) => (
              <TabPanel key={props.useCase} px={{ base: 0, md: 4 }}>
                <UseCase {...props} />
              </TabPanel>
            ))}
        </TabPanels>
      </Tabs>
    </LandingContainer >
  )
}

const GettingStarted = () => {

  React.useEffect(() => {
    // Preload images
    const images = ['/logo-square-white.jpg'];
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  const [currentStep, setCurrentStep] = React.useState(0)
  const borderColor = useColorModeValue("gray.300", "gray.500")
  return (
    <LandingContainer px={{ base: 2, sm: 4, lg: 16 }} title={<T>Getting started</T>}>
      <Text mb='8'>
        <T>
          _lp:getting_started:p
        </T>
      </Text>
      <SimpleGrid columns={12} rowGap={12} columnGap={{ base: 0, md: 12 }}>
        <GridItem colSpan={{ base: 12, md: 6, lg: 4 }} as={VStack}>
          <VStack align='stretch' py={{ base: 0, lg: 12 }} h='full' justify='center'>
            <Step onClick={() => setCurrentStep(0)} icon={FaHammer} title={<T args={{ step: 1 }}>{'Step {step}'}</T>} text={<T>Create a flow by using a template or from scratch</T>} selected={currentStep === 0} />
            <Step onClick={() => setCurrentStep(1)} icon={FaLink} title={<T args={{ step: 2 }}>{'Step {step}'}</T>} text={<T>Connect the necessary integrations</T>} selected={currentStep === 1} />
            <Step onClick={() => setCurrentStep(2)} icon={FaWatchmanMonitoring} title={<T args={{ step: 3 }}>{'Step {step}'}</T>} text={<T>Monitor your flow activity</T>} selected={currentStep === 2} />
          </VStack>
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 6, lg: 8 }}>
          <HStack w='full' h='500px' align={{ base: 'start', md: 'center' }} justify='center'>
            {currentStep === 0 && <Box className="lp-card" height={"500px"} width={"full"} position='relative'>
              <Tag colorScheme="brandQuaternary" size='lg' borderRadius='10' gap={2} fontWeight='bold' p={{ base: 2, lg: 2 }} position='absolute' top='0' left='0%' transform='translate(20%, -50%)'>
                <Icon as={FaHammer} />
                <Text>
                  <T>
                    Build your flow
                  </T>
                </Text>
              </Tag>
              <ReactFlowProvider>
                <AnimatedFlow onClick={() => setCurrentStep(1)} />
                {/* <Demo/> */}
              </ReactFlowProvider>
            </Box>}
            {
              currentStep === 1 && <Step1 onClick={() => setCurrentStep(2)} />
            }
            {
              currentStep === 2 &&
              <Flex className="lp-card" direction='column' align='center' gap={4} position='relative' p='4' w='full' >
                <Tag colorScheme="green" size='lg' borderRadius='10' gap={2} fontWeight='bold' p={{ base: 2, lg: 2 }} position='absolute' top='0' left='0%' transform='translate(20%, -50%)'>
                  <Icon as={FaRocket} />
                  <Text>
                    <T>
                      Your flow is running!
                    </T>
                  </Text>
                </Tag>
                <VStack w='full' h='500px' align='stretch' p={4} >
                  <Box>
                    <Text mb='4'>
                      <T>
                        _lp:animated_demo:monitoring:p
                      </T>
                    </Text>
                  </Box>
                  <Accordion w="full" h='500px' rounded="lg" defaultIndex={[0]} overflowY='auto' allowToggle>
                    {[
                      {
                        type: 'Created',
                        head: '_lp:animated_demo:call_created:head',
                        details: {
                          '_hubspot:hs_timestamp': '2024-11-15 12:15:00',
                          '_hubspot:hs_call_title': '_lp:animated_demo:call_created:hs_call_title',
                          '_hubspot:hs_call_body': '_lp:animated_demo:call_created:hs_call_body',
                        }
                      },
                      {
                        type: 'Created',
                        head: '_lp:animated_demo:contact_created:head',
                        details: {
                          'First name': 'John',
                          'Last name': 'Doe',
                          'Email': 'john@supacompany.com',
                          'Role': 'COO',
                        }
                      },
                      {
                        type: 'Associated',
                        head: '_lp:animated_demo:contact_company_assoc:head',
                      },
                      {
                        type: 'Associated',
                        head: '_lp:animated_demo:contact_call_assoc:head',
                      },
                      {
                        type: 'Associated',
                        head: '_lp:animated_demo:contact_deal_assoc:head',
                      },
                      {
                        type: 'Updated',
                        head: '_lp:animated_demo:deal_updated:head',
                        details: {
                          'Amount': ['_lp:animated_demo:deal_updated:amount:before', '_lp:animated_demo:deal_updated:amount:after'],
                          'Close date': ['2024-12-12', '2025-01-18']
                        }
                      },
                    ].map(({ type, head, details }) => <TableRow key={head} type={type} head={head} details={details} />)}
                  </Accordion>
                </VStack>
              </Flex>
            }
          </HStack >
        </GridItem>
      </SimpleGrid>
    </LandingContainer >
  );
};

const getSavings = (numSales, pricePerHour, locale) => {
  let ccy = "USD"
  if (locale == 'fr') {
    locale = 'fr-FR'
    ccy = 'EUR'
  } else {
    locale = 'en-US'
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: ccy,
    minimumFractionDigits: 0,
  }).format((numSales || 0) * (pricePerHour || 0) * 20 * 1.3);
}
const CostSimulator = () => {
  const brandColor = useColorModeValue("brandTernary.200", "brandTernary.100");
  const [numSales, setNumSales] = React.useState(10);
  const [hourlySalary, setHourlySalary] = React.useState<number | undefined>(45);
  const size = 'sm';
  const savings = getSavings(numSales, hourlySalary, 'en');

  return (
    <LandingContainer
      subtitle={<T>Savings simulator</T>}
      title={<T>
        _lp:simulator:title
      </T>}
      desc={
        <T>
          _lp:simulator:desc
        </T>
      }
    >
      <VStack
        className="lp-card"
        align="stretch"
        spacing={6}
        position='relative'
        p={4}
      >
        <Box
          className="lp-bg-img"
          // filter={`saturate(${saturation}) hue-rotate(${hue}deg) brightness(${brightness})`}
          backgroundImage={`url('/landing/bg13.jpeg')`}
        />
        <Box p='4' bg='white' rounded='10' shadow='md'>
          <SimpleGrid columns={12} gap='0' >
            <GridItem alignItems='start' colSpan={{ base: 12, lg: 5 }} as={VStack} align='stretch' spacing='4' borderWidth={{ base: '0px', md: '0px' }} borderColor='gray.200' p={{ base: 0, md: 8 }}>
              <VStack align='stretch'>
                <FormLabel>
                  <T>Number of salespeople in your company</T>
                </FormLabel>
                <HStack spacing={2}>
                  {[1, 10, 100, 1000].map((num) => (
                    <Button
                      key={num}
                      size={size}
                      onClick={() => setNumSales(num)}
                      variant={numSales === num ? 'solid' : 'outline'}
                      colorScheme="brand"
                      borderWidth='1px'
                    >
                      {num}
                    </Button>
                  ))}
                  <Box w='5rem'>
                    <Input
                      rounded='5'
                      size={size}
                      type="number"
                      value={numSales}
                      onChange={(e) => setNumSales(e.target.value ? Number(e.target.value) : '')}
                      placeholder={<T>Enter a custom number</T>}
                      borderColor={useColorModeValue('brand.300', 'brand.500')}
                    />
                  </Box>
                </HStack>
              </VStack>
              <VStack align='stretch'>
                <FormLabel>
                  <T>
                    Hourly salary
                  </T>
                </FormLabel>
                <HStack>
                  <Box w='4rem'>
                    <Input size={size} type="number" value={hourlySalary} onChange={(e) => setHourlySalary(e.target.value ? Number(e.target.value) : undefined)} />
                  </Box>
                  <Text>
                    <T>
                      $ / hour
                    </T>
                  </Text>
                </HStack>
              </VStack>
              <Button
                size='xs'
                onClick={() => {
                  setNumSales(10)
                  setHourlySalary(50)
                }}>
                <T>Reset</T>
              </Button>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 2 }} as={VStack} justify='center'>
              <Text transform={{ base: 'rotate(90deg)', lg: 'rotate(0deg)' }} fontSize="8xl" fontWeight='black' textAlign='center' color='brand.400'  >
                →
              </Text>
            </GridItem>
            <GridItem alignItems='center' colSpan={{ base: 12, lg: 5 }} as={VStack} justify='center' borderWidth={{ base: '0px', md: '0px' }} borderColor='gray.200' p={{ base: 0, md: 8 }}>
              <Text fontSize="xl" fontWeight='black'>
                <T>Save</T>
              </Text>
              <Text
                as="span"
                fontWeight="bold"
                fontSize="4xl"
                color={useColorModeValue('green.500', 'green.300')}
              >
                {savings}
              </Text>
              <Box>
                <Text as='span' fontSize="xl" fontWeight='black' ><T>every</T> </Text>
                <Text as='span' fontSize="xl" fontWeight='black' bg={brandColor} p='1' borderRadius="md">
                  <T>month</T>
                </Text>
              </Box>
            </GridItem>
          </SimpleGrid>
          <StackDivider w='full' h='1px' bg='gray.200' />
          <Text fontSize="sm" color="gray.500" textAlign="center"
          >
            <T args={{ hourlySalary }}>
              _lp:cost_simulator_assumptions
            </T>
          </Text>
        </Box>
      </VStack>
    </LandingContainer >
  );
};


const Templates = () => {
  const { templates, nodeTypes } = useData()
  const customNodes = React.useMemo(() => createCustomNodes({ nodeTypes, isDemo: true, defaultIsExpanded: false }), [nodeTypes])
  return <LandingContainer title="Our most popular templates 🔥">
    <VStack align='stretch'>
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={{ base: 8 }}
      >
        {
          templates?.slice(0, 3).map(({ template, edges, nodes }, ix) => <ExploreCard
            root="/gallery"
            key={template.id}
            template={template}
            edges={edges}
            nodes={nodes}
            customNodes={customNodes}
            bg={TEMPLATES_BGS[ix % TEMPLATES_BGS.length]}
          />)
        }
      </SimpleGrid>
      <HStack justify={{ base: 'center', md: 'end' }} spacing={4}>
        <Button mt='2' variant='link' leftIcon={<MdChevronRight />}>
          <Link href='/gallery'>
            See more templates
          </Link>
        </Button>
      </HStack>
    </VStack>
  </LandingContainer>
}


const LandingPage = () => {
  return (
    <>
      <VStack w='full' align='stretch' spacing={16}>
        <Box position='relative'>
          <Box
            className="lp-bg-img"
            display={{ base: 'none', md: 'block' }}
            opacity={0.1}
            transform='scaleY(-1) scaleX(-1)'
            filter='saturate(0)'
            backgroundImage={`linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 50%), url('/landing/bg15.jpeg')`}
          />
          <Box
            display={{ base: 'block', md: 'none' }}
            className="lp-bg-img"
            opacity={0.2}
            transform='scaleY(-1) scaleX(-1)'
            filter='saturate(-100)'
            backgroundImage={`linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 50%), url('/landing/bg15.jpeg')`}
          />
          {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => <Hero i={i} />)} */}
          <Hero />
        </Box>
        <Box id="use-cases" scrollMarginTop="100px" position='relative'>
          <Box
            className="lp-bg-img"
            opacity={0.2}
            filter='saturate(0)'
            // transform='scaleY(-1) scaleX(-1)'
            backgroundImage={`linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0) 66%, rgba(255, 255, 255, 1) 100%), url('/landing/bg15.jpeg')`} backgroundSize="cover"
          />
          <UseCases />
        </Box>
        <Box id="integrations" scrollMarginTop="100px" position='relative'>
          <IntegrationLogos />
          <RequestIntegration />
        </Box>
        {/* <Box id="getting-started" scrollMarginTop="100px">
          <GettingStarted />
        </Box> */}
        <Box id="cost-simulator" scrollMarginTop="100px" position='relative'>
          <Box
            className="lp-bg-img"
            opacity={0.6}
            filter='saturate(0)'
            transform='scaleY(-1) scaleX(-1)'
            backgroundImage={`linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 1) 100%), url('/landing/bg15.jpeg')`} backgroundSize="cover"

          />
          <CostSimulator />
        </Box>
        <Box id="templates" scrollMarginTop="100px" display='none'>
          <Templates />
        </Box>
        {/* <Box id="testimonials" scrollMarginTop="100px" display='none'>
          <Testimonials />
        </Box> */}
        {/* <Box id="use-cases">
        <UseCases />
      </Box> */}
        {/* <Box id="features">
        <Features />
      </Box> */}
        <Box id="pricing" scrollMarginTop="100px">
          <Pricing />
        </Box>
        {/* <Box id="integrations" scrollMarginTop="100px">
          <IntegrationsWidget />
        </Box> */}
      </VStack>
      <Box id="faq" scrollMarginTop="100px" position='relative'>
        <Box
          display={{ base: 'none', md: 'block' }}
          className="lp-bg-img"
          opacity={0.2}
          transform='scaleY(-1) scaleX(-1)'
          filter='saturate(0)'
          backgroundImage={`linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0) 66%, rgba(255, 255, 255, 1) 100%), url('/landing/bg13.jpeg')`}
        />
        <Faq />
      </Box>
    </>
  );
};

export default LandingPage;
