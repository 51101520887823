"use client";

import {
  Flex,
  Box,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { MdEmail } from "@react-icons/all-files/md/MdEmail";
import { SiDiscord } from "@react-icons/all-files/si/SiDiscord";
import T from "src/renderer/local-text";

const Contact = () => {
  return (
    <Flex p={4} justify='center' position='relative' className="lp-card">
      <Box
        className="lp-bg-img"
        opacity={0.4}
        // transform='scaleX(-1)'
        // filter={`saturate(0.0)`}
        filter='hue-rotate(160deg) saturate(1.5)'
        transform={`rotate(180deg)`}
        backgroundImage={`url('/landing/bg15.jpeg')`}
        // filter={`hue-rotate(20deg)`}
        // backgroundImage={`url('/landing/bg13.jpeg')`}
      />
      <Box p={4} bg='white' rounded='10' shadow='md'>
        <Text
          mt={{ sm: 3, md: 3, lg: 5 }}
        >
          <T>Have another question? Contact us!</T>{' '}
          <Text as='span' fontWeight='bold'><T>We answer quickly.</T></Text>
        </Text>
        <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
          <VStack pl={0} spacing={3} align="stretch" fontWeight={600}>
            <HStack>
              <MdEmail />
              <Link isExternal href="mailto:hello@tryplurally.com">
                hello@tryplurally.com
              </Link>
            </HStack>
            <HStack display='none'>
              <SiDiscord />
              <Link href="https://discord.gg/aGpMJfYpDb" isExternal>
                Discord
              </Link>
            </HStack>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default Contact;
