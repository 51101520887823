import React, { useState, useEffect } from 'react';
import {
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { createCustomNodes } from 'src/appUtils';
import SCHEMAS from "src/res/demo-node-types.json";
import { Box, Spinner, VStack, Center, Text, Icon, HStack, Heading, SimpleGrid, GridItem } from '@chakra-ui/react';
import T from 'src/renderer/local-text';
import AnimatedSVGEdge from './animated-edge';
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle';
import { motion, AnimatePresence } from 'framer-motion';

const TIMEOUT = 1000

const elements = [
  {
    _type: 'node',
    id: "mic",
    type: "Audio Recording",
    data: {
      src_handles: ["recording"],
      name: "New audio recording",
      overrides:{
        kls: "Email",
        icon: "outlook.png",
        tooltip: "Audio Recording",
        isExpanded: false,
      },
      _overrides: [
        {
          kls: "Audio",
          icon: "iphone-voice-recorder.png",
          isExpanded: false,
          tooltip: "Audio Recording",
        },
        {
          kls: "Teams",
          icon: "teams.png",
          isExpanded: false,
          tooltip: "Teams",
        },
        {
          kls: "Notes",
          icon: "notes.png",
          isExpanded: false,
          tooltip: "Notes",
        },
        {
          kls: "Leexi",
          icon: "leexi_logo.jpeg",
          isExpanded: false,
          tooltip: "Leexi",
        },
        {
          kls: "Meet",
          icon: "meet.png",
          isExpanded: false,
          tooltip: "Meet",
        },
        {
          kls: "Slack",
          icon: "slack.svg",
          isExpanded: false,
          tooltip: "Slack",
        },
        // {
        //   kls: "Instagram",
        //   icon: "instagram.svg",
        //   isExpanded: false,
        // },
        {
          kls: "Email",
          icon: "outlook.png",
          isExpanded: false,
          tooltip: "Email",
        }
      ],
    },
    position: { x: 125, y: 35 },
  },
  {
    _type: 'node',
    id: "plurallyai",
    type: "Plurally",
    data: {
      tgt_handles: ["summary"],
      src_handles: ["hubspot_commands"],
      name: "Generate HubSpot records",
      overrides: {
        kls: "AI",
        isExpanded: false,
        borderRadius: "full",
        className: "animated-border"
      },
    },
    position: { x: 180, y: 186 },
  },
  {
    _type: 'edge',
    label: "#e0f6de",
    id: "mic-plurallyai-summary",
    source: "mic",
    target: "plurallyai",
    sourceHandle: "recording",
    targetHandle: "summary",
    animated: false,
    type: 'animatedSvg',
  },
  {
    _type: 'node',
    id: "hubspot",
    type: "HubspotAuto",
    data: {
      tgt_handles: ["hubspot_commands"],
      overrides: {
        kls: "HubSpot",
        icon: "hubspot.svg",
        isExpanded: false,
        tooltip: "HubSpot",
      },
      _overrides: [{
        kls: "Salesforce",
        icon: "salesforce.svg",
        isExpanded: false,
        tooltip: "Salesforce",
      }, {
        kls: "Portwin",
        icon: "portwin.png",
        isExpanded: false,
        tooltip: "Portwin",
      }, {
        kls: "Overloop",
        icon: "overloop.png",
        isExpanded: false,
        tooltip: "Overloop",
      }, {
        kls: "Pipedrive",
        icon: "pipedrive.png",
        isExpanded: false,
        tooltip: "Pipedrive",
      }],
      name: "Sync CRM",
    },
    input_schema: {
      properties: {
        hubspot_commands: {
          type: "array",
          title: "HubSpot commands",
          items: {
            type: "string",
            title: "Command",
          },
        },
      },
    },
    position: { x: 135, y: 330 },
  },
  {
    _type: 'edge',
    label: '#CFF5E7',
    id: "plurallyai-hubspot-commands",
    source: "plurallyai",
    target: "hubspot",
    sourceHandle: "hubspot_commands",
    targetHandle: "hubspot_commands",
    animated: false,
    type: 'animatedSvg',
  },
];

const OUTPUTS = [
  { text: "Call created", color: "brand.100" },
  { text: "Contact created", color: "brandSecondary.100" },
  { text: "Deal created", color: "brand.100" },
  { text: "Contact associated", color: "brandQuaternary.100" },
  { text: "Deal associated", color: "brand.50" },
  { text: "Task created", color: "brandSecondary.50" },
  { text: "Task associated", color: "brandSecondary.200" },
  { text: "Note created", color: "brand.100" },
  { text: "Deal updated", color: "brandSecondary.100" },
  { text: "Company updated", color: "brandQuaternary.100" },
  { text: "Contact updated", color: "brand.200" },
]

const AnimatedFlow = () => {
  const [customNodes] = useState(createCustomNodes({
    nodeTypes: SCHEMAS,
    isDemo: true,
  }));
  const [nodes, setNodes, onNodesChange] = useNodesState(elements.filter(e => e._type === 'node'));
  const [edges, setEdges, onEdgesChange] = useEdgesState(elements.filter(e => e._type === 'edge'));
  const [outputs, setOutputs] = useState([]);
  const [step, setStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStep(prevStep => {
        setNodes(prevNodes => {
          const updatedNodes = prevNodes.map(node => {
            if (node.data?._overrides) {
              return {
                ...node,
                data: {
                  ...node.data,
                  overrides: node.data._overrides[(prevStep + 1) % node.data._overrides.length],
                },
              };
            }
            return node;
          });
          return updatedNodes;
        });
        return prevStep + 1;
      });
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setOutputs(outputs => {
        return [{ ...OUTPUTS[outputs.length % OUTPUTS.length], id: Math.round(Math.random() * 10000) }, ...outputs]
      });
    }, TIMEOUT * 2)
    return () => clearInterval(interval)
  }, [])

  return (
    <SimpleGrid h='500px' p='4' columns={12}>
      <GridItem colSpan='4'>
        <VStack minW='12rem' align='stretch'>
          <Heading as='h3' size='sm'>
            <T>
              CRM automated actions
            </T>
          </Heading>
          <AnimatePresence>
            {outputs.slice(0, 5).map(({ text, id }) => (
              <motion.div
                key={id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                // exit={{ opacity: 0, x: 20 }}
                transition={{ duration: 0.5 }}
              >
                <HStack
                  bg="white"
                  p="2"
                  rounded="4"
                  borderWidth="2px"
                  borderColor="gray.200"
                  shadow="sm"
                  fontSize={{ base: "xs", lg: "md" }}
                >
                  <Icon as={FaCheckCircle} color="green.500" />
                  <Text><T>{text}</T></Text>
                </HStack>
              </motion.div>
            ))}
          </AnimatePresence>
        </VStack>
      </GridItem>
      <GridItem colSpan='8'>
        <Box h="full">
          {customNodes ? (
            <ReactFlow
              // nodeExtent={nodeExtent}
              deleteKeyCode={null}
              onNodesChange={(changes) => {
                changes.forEach((change) => {
                  if (change.position) {
                    console.debug(change.position.x, change.position.y);
                  }
                });
                onNodesChange(changes)
              }}
              onEdgesChange={() => null}
              onConnect={() => null}
              edgesFocusable={false}
              nodesConnectable={false}
              nodesFocusable={true}
              elementsSelectable={false}
              panOnScroll={false}
              preventScrolling={false}
              nodeTypes={{ ...(customNodes || {}) }}
              nodes={nodes}
              edges={edges}
              edgeTypes={{
                animatedSvg: AnimatedSVGEdge,
              }}
              fitView
            >
            </ReactFlow>
          ) : (
            <Center>
              <Spinner />
            </Center>
          )}
        </Box>
      </GridItem>
    </SimpleGrid>
  );
};

export default AnimatedFlow;
