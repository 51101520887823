'use client'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Text,
    Stack,
    Box,
    SimpleGrid,
    GridItem,
    VStack,
    Heading,
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'
import LandingContainer from 'src/home/LandingContainer'
import Contact from 'src/home/contact'
import T from 'src/renderer/local-text'

const FaqEntry = ({ question, answer }) => {
    return (
        <AccordionItem>
            <AccordionButton
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={4}>
                <Text align='start' fontWeight='bold'>{question}</Text>
                <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
                {answer}
            </AccordionPanel>
        </AccordionItem>
    )
}

const Faq = () => {
    return (
        <LandingContainer
            pt={{ base: 4, lg: 24 }} px={{ base: 0, md: 16 }}
            subtitle='FAQ'
            title={<T>Frequently asked questions</T>}>
            <SimpleGrid spacing={{ base: 0, md: 4 }} columns={{ base: 1, md: 12 }}>
                <GridItem colSpan={{ base: 1, md: 6, lg: 4 }} order={{ base: 1, md: 0 }}>
                    <Contact />
                </GridItem>
                <GridItem colSpan={{ base: 1, md: 6, lg: 8 }} order={{ base: 0, md: 1 }}>
                    <Accordion allowMultiple width="full" rounded="lg" defaultIndex={[0]}>
                        {[
                            {
                                title: '_faq:what_is_plurally:title',
                                ps: ['_faq:what_is_plurally:p1']
                            },
                            {
                                title: '_faq:how_do_i_know:title',
                                ps: ['_faq:how_do_i_know:p1']
                            },
                            {
                                title: '_faq:data_privacy:title',
                                ps: ['_faq:data_privacy:p1', '_faq:data_privacy:p2', '_faq:data_privacy:p3', '_faq:data_privacy:p4'],
                                strong: '_faq:data_privacy:strong'
                            },
                            {
                                title: '_faq:passwords:title',
                                ps: ['_faq:passwords:p1', '_faq:passwords:p2', '_faq:passwords:p3'],
                                strong: '_faq:passwords:strong'
                            },
                        ].map(({ title, ps, strong }) => <FaqEntry key={title} question={<T>{title}</T>} answer={
                            <VStack align='stretch'>
                                {ps.map(p => <Text key={p}><T>{p}</T></Text>)}
                                {strong && <Text fontWeight='bold'><T>{strong}</T></Text>}
                            </VStack>
                        } />
                        )}
                    </Accordion >
                </GridItem >
            </SimpleGrid >
        </LandingContainer >
    )
}

export default Faq
