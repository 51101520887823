"use client";

import {
  Box,
  Text,
  VStack,
  useColorModeValue,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import LandingContainer from "./LandingContainer";
import React from "react";
import T from "src/renderer/local-text";
import { ContactApi } from "typescript-axios";
import axios, { getAxiosParams } from "src/lib/axios.config";


const CONTACT_API = new ContactApi(getAxiosParams(), undefined, axios)

const CRMForm = () => {
  const [selectedCRM, setSelectedCRM] = React.useState('');

  const handleCRMChange = (event) => {
    setSelectedCRM(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    CONTACT_API.contactNewApiV1ContactContactNewPost(
      e.target.firstname.value,
      e.target.lastname.value,
      e.target.email.value,
      e.target.company.value,
      selectedCRM === 'Other' ? e.target.otherCRM.value : selectedCRM,
    )
  };

  return (
    <Box maxW="400px" mx="auto" bg='white' p='12' rounded='10' shadow='xl'>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="firstname" isRequired>
            <FormLabel><T>First name</T></FormLabel>
            <Input placeholder="Nathalie" />
          </FormControl>

          <FormControl id="lastname" isRequired>
            <FormLabel><T>Last name</T></FormLabel>
            <Input placeholder="Doe" />
          </FormControl>

          <FormControl id="email" isRequired type="email">
            <FormLabel><T>Email</T></FormLabel>
            <Input placeholder="nathalie@abc.com" />
          </FormControl>

          <FormControl id="company" isRequired>
            <FormLabel><T>Company</T></FormLabel>
            <Input placeholder="ABC Corp" />
          </FormControl>

          <FormControl id="crm" isRequired>
            <FormLabel><T>CRM</T></FormLabel>
            <Select placeholder={<T>Select CRM</T>} onChange={handleCRMChange}>
              <option value="Salesforce">Salesforce</option>
              <option value="HubSpot">HubSpot</option>
              <option value="Other"><T>Other</T></option>
            </Select>
          </FormControl>

          {selectedCRM === 'Other' && (
            <FormControl id="otherCRM" isRequired>
              <FormLabel><T>Specify CRM</T></FormLabel>
              <Input placeholder='CRM' />
            </FormControl>
          )}
          <Button type="submit" colorScheme="brand" width="full" >
            <T>Submit</T>
          </Button>
        </VStack>
      </form>
    </Box>
  );
};


const Pricing = () => {
  return (
    <Box mb='4'>
      <LandingContainer pt={4} mb='8' title={<T>_lp:pricing:title</T>}
        subtitle={<T>_lp:pricing:subtitle</T>}
        desc={
          <T>
            _lp:pricing:desc
          </T>}>
      </LandingContainer>
      <Box
        position='relative' className="lp-card" p={{ base: 4, sm: '8' }}
        borderRadius='0'
        shadow='none'
        border='none'
      >
        <CRMForm />
        <Box
          className="lp-bg-img"
          opacity={0.4}
          backgroundImage={`url('/landing/bg15.jpeg')`}
        />
      </Box>
    </Box>
  );
};

export default Pricing;
